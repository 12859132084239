import React from "react";
import styled from "styled-components";

export default function InfoText() {
  return (
    <Wrapper>
      <Castel src="/images/backgrounds/castel.png" />
      <Title src="/images/icons/legenda.svg" alt="Garlic Bistro" />
      <Subtitle>
        că odată ce guști din preparatele noastre, te vei întoarce de fiecare
        dată când ai ocazia.
        <br />
        <br />
        Poate e doar un mit, așa că cel mai bine e să vii și să încerci! Te
        așteptăm cu drag!
      </Subtitle>
    </Wrapper>
  );
}

const Castel = styled.img`
  position: absolute;
  top: -100px;
  left: -650px;
  overflow: visible;
  @media (max-width: 720px) {
    left: -300px;
    top: 180px;
  }
`;

const Wrapper = styled.div`
  overflow: visible;
  position: relative;
  padding: 100px;
  padding-left: 250px;
  padding-top: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  flex-flow: wrap;
  margin-right: 100px;

  /* @media (max-width: 1400px) {
    max-width: 500px;
  } */
  @media (max-width: 720px) {
    margin-top: 30px;
    padding: 30px;
    margin-right: 0;
    padding-bottom: 300px;
  }
  @media (max-width: 400px) {
    margin-top: 100px;
    padding: 30px;
    padding-bottom: 300px;
  }
`;

const Title = styled.img`
  width: 400px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Subtitle = styled.text`
  font-weight: 200;
  font-size: 20px;
  line-height: 110%;
  /* or 15px */
  color: #ffffff;
  opacity: 0.8;
`;
