import React from "react";
import styled from "styled-components";

export default function Rezervari() {
  return (
    <Wrapper id="contact">
      <Batman src="/images/elements/batman.png" />
      <CardWrapper>
        <Card>
          <Box>
            <Title>ADRESA</Title>
            <Subtitle>
              Strada Inocențiu Micu Klein 13
              <br />
              Cluj-Napoca, 400087, Romania
            </Subtitle>
          </Box>
          <Divider />
          <Box>
            <Title>INFO & REZERVĂRI</Title>
            <Subtitle>
              Telefon: 0732 100 700
              <br />
              Email: contactbistrogarlic@gmail.com
            </Subtitle>
          </Box>
          <Divider />
          <Box>
            <Title>ORAR</Title>
            <Subtitle>
              Luni - Duminică
              <br />
              10:00 - 23:00
            </Subtitle>
          </Box>
        </Card>
      </CardWrapper>
    </Wrapper>
  );
}

const Box = styled.div`
  padding: 20px;
`;
const Divider = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  height: 60px;
  @media (max-width: 800px) {
    display: none;
  }
`;
const Title = styled.h1`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  opacity: 0.6;
  :link {
    color: #ffffff;
  }
  @media (max-width: 800px) {
    text-align: center;
  }
`;

const Batman = styled.img`
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding: 50px;
  margin-top: 150px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  @media (max-width: 1260px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 1260px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const CardWrapper = styled.div`
  margin: 10px;
  width: 100%;
  // max-width: 545px;
  background: rgba(0, 0, 0, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 30px;

  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;
`;
