import React from "react";
import styled from "styled-components";

export default function TopSection() {
  return (
    <Wrapper>
      <Logo src="/images/logos/garliclogo.svg" alt="Garlic Bistro" />
      <Circle src="../images/elements/circles.svg" />
      <Farfurie src="../images/food/plate1.png" />
    </Wrapper>
  );
}

const Logo = styled.img`
  width: 400px;

  @media (max-width: 720px) {
    margin-top: -300px;
    width: 250px;
  }
`;

const Circle = styled.img`
  position: absolute;
  right: 0;
  top: 200px;
  z-index: -1;
`;

const Farfurie = styled.img`
  width: 1000px;
  position: absolute;
  right: -350px;
  top: 500px;
  z-index: 0;
  :hover {
    transform: rotate(180deg);
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
  }
  @media (max-width: 1300px) {
    top: 700px;
    width: 750px;
    right: -250px;
  }
  @media (max-width: 1180px) {
    top: 800px;
    width: 550px;
    right: -350px;
  }
  @media (max-width: 720px) {
    top: 450px;
    width: 500px;
    right: -150px;
  }
  @media (max-width: 400px) {
    top: 350px;
    width: 500px;
    right: -150px;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/images/backgrounds/fundal.png");
  background-size: cover;
`;

const HeadText = styled.text`
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 72px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  @media (max-width: 720px) {
    line-height: 1;
    margin-bottom: 30px;
  }
`;

const SubHeading = styled.text`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */

  text-align: center;

  color: #97a6b4;

  @media (max-width: 720px) {
    display: none;
  }
`;
