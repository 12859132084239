import React from "react";
import styled from "styled-components";

export default function CardSection() {
  return (
    <Wrapper>
      <CardWrapper>
        <Card>
          <TextWrapper>
            <CardTitle>Descoperă meniul</CardTitle>
            <CardSubtitle>
              Alege dintre delicatesele "Garlic" și bucură-te de savoarea
              Transilvaniei!
            </CardSubtitle>
            <ButtonWrapper>
              <Buton>
                <a href="/meniuqr">
                  <GetStarted>Română</GetStarted>
                </a>
              </Buton>
              <Buton>
                <a href="/meniuqr-en">
                  <GetStarted>English</GetStarted>
                </a>
              </Buton>
            </ButtonWrapper>
          </TextWrapper>
          <PhotoWrapper>
            <Photo />
          </PhotoWrapper>
        </Card>
      </CardWrapper>
    </Wrapper>
  );
}

const Buton = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 30px;
`;
const Wrapper = styled.div`
  position: relative;
  padding: 50px;
  margin-top: 150px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  @media (max-width: 1260px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 1260px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
  }
`;
const Card = styled.div`
  display: flex;

  @media (max-width: 1110px) {
    flex-direction: column-reverse;
  }
`;

const CardWrapper = styled.div`
  margin: 10px;
  width: 100%;
  // max-width: 545px;
  background: rgba(0, 0, 0, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;
`;

const TextWrapper = styled.div`
  margin: 30px;
`;

const CardTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  color: #ffffff;
  margin-bottom: 16px;
`;

const CardSubtitle = styled.h2`
  font-weight: normal;
  font-size: 14px;
  line-height: 110%;
  color: #ffffff;
  opacity: 0.6;
`;

const PhotoWrapper = styled.div`
  min-width: 700px;
  min-height: 300px;
  border-radius: 0px 30px 30px 0px;
  overflow: hidden;
  @media (max-width: 1110px) {
    min-width: auto;
  }

  @media (max-width: 1110px) {
    height: 350px;
    border-radius: 30px 30px 0px 0px;
  }
`;

const Photo = styled.div`
  height: 100%;
  width: 100%;
  background-image: url("/images/food/usturoi.jpg");
  background-size: cover;
  background-position: center;

  @media (max-width: 650px) {
    background-image: url("/images/food/usturoicolt.jpeg");
    background-position: top;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 170px;
  text-align: center;
  margin: auto;
`;

const GetStarted = styled.text`
  background-color: #ffffff;
  padding: 10px;
  color: #1a1f25;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  margin-top: 25px;
`;
