import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import TopSection from "../components/sections/TopSection";
import InfoText from "../components/sections/InfoText";
import styled from "styled-components";
import CardSection from "../components/sections/CardSection";
import Rezervari from "../components/sections/Rezervari";
import InstaFeed from "../components/sections/utils/InstaFeed";
import "../components/sections/utils/InstaFeed.css";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";

import Swal from "sweetalert2";

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Acasă" />
      <CookyWrapper>
        <Cookies
          location="bottom"
          buttonText="Accept"
          containerClasses="cooky"
          buttonClasses="cookyBtn"
          contentClasses="contentCooky"
          buttonWrapperClasses="btnWrapper"
          declineButtonClasses="cookyBtn"
          enableDeclineButton="true"
          declineButtonText="Decline"
          onDecline={() => {
            Swal.fire({
              title: "Do you want to disable cookies?",
              text: "Bistro Garlic is using Google Analytics cookies to provide an insight into the traffic and the usage patterns of web applications by tracking, reporting, and analyzing web site traffic.",
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: "Disable",
              denyButtonText: `Cancel`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                Swal.fire("Saved!", "", "success");
              } else if (result.isDenied) {
                Swal.fire("Thank you!", "", "info");
              }
            });
          }}
          cookieName="gatsby-gdpr-google-analytics"
          disableStyles="true"
        >
          Bistro Garlic folosește cookies. Vizitează{" "}
          <Link to="/politica-cookies">
            politica de folosire a cookies-urilor
          </Link>
          pentru detalii.
        </Cookies>
      </CookyWrapper>
      <TopSection />
      {/* <Slider /> */}
      <SectionWrapper>
        <Circle src="../images/elements/circles.svg" />
        <InfoText />
        <CardSection />
        <Rezervari />
        {/* <InstaFeed /> */}
      </SectionWrapper>
    </Layout>
  );
};

export default IndexPage;

const CookyWrapper = styled.div`
  .cooky {
    box-shadow: rgb(0 0 0 / 25%) 0px 40px 80px,
      rgb(255 255 255 / 15%) 0px 0px 0px 0.5px inset;
    backdrop-filter: blur(20px) !important;

    align-items: baseline;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0px;
  }

  .contentCooky {
    /* flex: 1 0 300px; */
    margin: 15px;
    a {
      color: rgb(255, 255, 255);
      text-decoration: underline;
    }
  }

  .cookyBtn {
    cursor: pointer;
    /* flex: 0 0 auto; */
    width: 100px !important;
    margin: 15px;

    background: rgba(0, 0, 0, 1);
    border: none;
    border-radius: 30px;
    box-shadow: rgb(147 231 221 / 30%) 0px 20px 40px;
    cursor: pointer;
    text-align: center;
    padding: 12px 0px;
    width: 100%;
    font-family: inherit;
    font-weight: 600;
    position: relative;
    color: white;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    :hover {
      transform: translateY(-2px);
      box-shadow: rgb(0 0 0 / 15%) 0px 20px 40px,
        rgb(0 0 0 / 30%) 0px 0px 0px 0.5px inset,
        rgb(0 0 0 / 10%) 0px 10px 40px inset;
    }
  }

  .btnWrapper {
    padding-right: 30px;
    display: flex;
    /* flex-flow: row-reverse; */
  }
`;

const Cookies = styled(CookieConsent)``;

const SectionWrapper = styled.div`
  //overflow: hidden;
  max-width: 1240px;
  margin: auto;

  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;
`;

const Circle = styled.img`
  position: absolute;
  left: 0px;
  top: 1500px;
  z-index: -1;
  transform: rotate(180deg);
`;
